<script setup>
const props = defineProps({
  videoId: { type: String, required: true },
  height: { type: String, default: '390' },
  width: { type: String, default: '640' },
  playerVars: {
    type: Object,
    default () {
      return { autoplay: 0 }
    },
  },
})

const youtubeContainer = ref(null)
const player = ref(null)

onMounted(() => {
  const tag = document.createElement('script')
  tag.src = 'https://www.youtube.com/iframe_api'
  const firstScriptTag = document.getElementsByTagName('script')[0]
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

  window.onYouTubeIframeAPIReady = () => {
    player.value = new YT.Player(youtubeContainer.value, {
      height: props.height,
      width: props.width,
      videoId: props.videoId,
      playerVars: props.playerVars,
    })
  }
})
</script>

<template>
  <div ref="youtubeContainer" class="mx-auto youtube-container" />
</template>

<style scoped>
.youtube-container {
  aspect-ratio: 16 / 9;
  width: 100%;
}
</style>
